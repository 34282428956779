<!--拒收退货-->
<template>
  <div class="container">
    <!-- 按钮列表 -->
    <div class="btnList1" ref="btnList">
      <el-button
        v-if="hasPermiss('bgrk_juShouTuiHuo:add')"
        class="itemBtn btnColor"
        @click="NewReject"
        >新建拒收</el-button
      >
      <!-- <el-button v-if="hasPermiss('bgrk_juShouTuiHuo:update')" class="itemBtn btnColor" @click="">修改</el-button> -->
      <el-button
        v-if="hasPermiss('bgrk_juShouTuiHuo:del')"
        class="itemBtn btnColor"
        @click="rejection"
        >删除拒收</el-button
      >
      <el-button
        v-if="hasPermiss('bgrk_juShouTuiHuo:handel')"
        class="itemBtn btnColor"
        @click="RejectionTreatment"
        >拒收处理</el-button
      >
      <el-button class="itemBtn btnColor" @click="myModel">表格设置</el-button>
      <el-dropdown @command="handleCommand_dc">
        <el-button class="dropdownBtn">
          导出<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="dc">导出</el-dropdown-item>
          <el-dropdown-item command="dcmb">下载导出模板</el-dropdown-item>
          <el-dropdown-item command="scmb">保存(上传)模板</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!-- 查询条件框 -->
    <el-form :model="queryData">
      <div
        ref="queryCriteria"
        class="HeadInputbox"
        :class="{ HeadInputbox1: judge, active: isActive }"
      >
        <div class="item_right">
          <div class="singleinp">
            <div class="singleinpleft">快递单号:</div>
            <div class="singleinpright">
              <el-input
                class="queryInput"
                clearable
                v-model="number1"
                placeholder="请输入内容"
              >
              </el-input>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">会员号:</div>
            <div class="singleinpright">
              <el-input
                class="queryInput"
                clearable
                v-model="queryData.memberId"
                placeholder="请输入内容"
              >
              </el-input>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">会员名称:</div>
            <div class="singleinpright">
              <el-input
                class="queryInput"
                clearable
                v-model="queryData.nickname"
                placeholder="请输入内容"
              >
              </el-input>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">拒签类型:</div>
            <div class="singleinpright">
              <el-select
                class="queryInput"
                v-model="queryData.rejectionStatus"
                filterable
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in rjsArr"
                  :key="item.statusVal"
                  :label="item.showName"
                  :value="item.statusVal"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">拒收状态:</div>
            <div class="singleinpright">
              <el-select
                class="queryInput"
                v-model="queryData.isRejection"
                filterable
                clearable
                placeholder="请选择"
              >
                <el-option label="否" value="0"></el-option>
                <el-option label="是" value="1"></el-option>
              </el-select>
            </div>
          </div>
          <div class="singleinp_time">
            <div class="timeBox queryItemTime">
              <div class="right_inpu">
                <el-date-picker
                  value-format="yyyy-MM-dd"
                  v-model="time_first"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="申请开始日期"
                  end-placeholder="申请结束日期"
                >
                </el-date-picker>
              </div>
            </div>
          </div>
        </div>
        <div class="item_left" v-if="hasPermiss('bgrk_juShouTuiHuo:query')">
          <div class="singleinpbtn">
            <el-button
              class="seniorbut"
              @click="queryBtn_ok"
              icon="el-icon-search"
              >查询</el-button
            >
            <el-tooltip content="查询项恢复初始状态" placement="top">
              <div class="condition" @click="resetFeild">清除</div>
            </el-tooltip>
            <el-tooltip content="查询更多条件" placement="top">
              <div class="condition" @click="more">
                {{ MoreConditions }}
              </div>
            </el-tooltip>
          </div>
        </div>
      </div>
    </el-form>
    <!-- 表格 -->
    <el-table
      :height="tableHeigth"
      ref="mytable"
      :header-cell-style="{ background: '#f0f0f0' }"
      :row-class-name="tableRowClassName"
      :data="tableData"
      size="small"
      border
      show-summary
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        fixed="left"
        align="center"
        label="序号"
        type="index"
        width="55"
      >
      </el-table-column>
      <el-table-column fixed="left" align="center" type="selection" width="55">
      </el-table-column>
      <template v-for="(item, index) in myTableHeard">
        <el-table-column
          :key="index"
          :label="item.name"
          :prop="item.field"
          :min-width="item.width"
          align="center"
          :show-overflow-tooltip="true"
          :data-isTotal="item.isTotal"
          v-if="item && item.isShow"
        >
        </el-table-column>
      </template>
    </el-table>
    <!-- 分页组件 -->
    <paging
      ref="pags"
      :pageNum="pageNum"
      :total="total"
      :sizeList="sizeList"
      :size="size"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
    </paging>
    <!-- //排序 -->
    <sorttable
      :show_sortTableHeard="show_sortTableHeard"
      :allCheck="allCheck"
      :sortName="sortName"
      :tabelHeadeTitle="tabelHeadeTitle"
      :originalTabelHeadeTitle="originalTabelHeadeTitle"
      :selectLength="selectLength"
      @cancelSortHeard="cancelSortHeard"
      @confirmOk="confirmOk"
      @checktab="checktab"
      @checkAll="checkAll"
      @closeDias="closeDias"
    ></sorttable>
    <el-dialog
      title="上传编辑好的导出模板"
      :visible.sync="show_upload"
      append-to-body
      width="40%"
      center
    >
      <el-upload
        :action="uploadUrl"
        :show-file-list="true"
        :file-list="fileList"
        :limit="1"
        :http-request="uploadSectionFile"
      >
        <el-button size="small" type="primary">点击上传</el-button>
        <div slot="tip" class="el-upload__tip">上传模板</div>
      </el-upload>
      <div slot="footer" class="dialog-footer">
        <el-button @click="show_upload = false">关 闭</el-button>
      </div>
    </el-dialog>
    <el-dialog title="收货地址" :visible.sync="dialogFormVisible">
      <div class="headebut">
        <el-button type="warning">确定</el-button>
      </div>
      <el-form :model="form" label-position="left" label-width="80px">
        <el-form-item label="快递单号">
          <el-input v-model="form.name" autocomplete="off" class="inpustyle">
          </el-input>
          <el-button type="warning" size="small" class="addbut">添加</el-button>
        </el-form-item>

        <el-form-item label="货架号">
          <el-select
            v-model="value"
            filterable
            placeholder="模糊查询或选择"
            class="inpustyle"
          >
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="货区">
          <el-select
            v-model="form.region"
            placeholder="请选择"
            class="inpustyle"
          >
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-table :data="tableData1" border style="width: 800px">
        <el-table-column width="150" label="序号">
          <template></template>
        </el-table-column>
        <el-table-column
          v-for="(itme, index) in Header"
          :key="index"
          :prop="itme.field"
          :label="itme.name"
          width="142"
        >
          <el-input class="inpstyle"></el-input>
        </el-table-column>
        <el-table-column width="150" label="操作">
          <template slot-scope="scope">
            <el-button @click="delbut(scope.$index)">移除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { apiList, Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
import draggable from "vuedraggable"; //拖拽组件
import sorttable from "@/components/sortTemplate.vue";
import paging from "@/components/pagings.vue";

export default {
  inject: ["reload"],
  components: {
    // mytables,
    draggable,
    sorttable,
    paging,
  },
  data() {
    return {
      number1: "",
      value: "",
      tableData1: [],
      originalTabelHeadeTitle: [],
      Header: [
        //表头
        {
          name: "快递单号",
          field: "category",
        },
        {
          name: "货架号",
          field: "UnitPrice",
        },
        {
          name: "库区",
          ReservoirArea: "category",
        },
      ],
      dialogFormVisible: false,
      form: {
        name: "",
        region: "",
      },
      queryData: {},
      time_first: [],
      options: [
        {
          name: "a",
          val: 0,
        },
        {
          name: "b",
          val: 1,
        },
      ],
      // ======公用组件==========
      pageNum: 1, //页码
      total: 0,
      sizeList: [10, 20, 50, 100, 200],
      size: 50, //一页几条
      show_sortTableHeard: false, //显示弹窗
      show_upload: false, //显示上传弹窗
      myTableHeard: [], //我的表头
      selectLength: 0, //显示的长度
      allCheck: false, //全选
      tableHeigth: "50vh",
      tabelHeadeTitle: [

        {
          name: "拒收单号",
          field: "number",
          width: "80",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "仓库",
          field: "storageName",
          width: "80",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "会员名称",
          field: "nickName",
          width: "120",
          sort: 3,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "会员号",
          field: "memberId",
          width: "80",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "货架号",
          field: "frameName",
          width: "80",
          sort: 6,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "包裹状态",
          field: "packageStatusShow",
          width: "140",
          sort: 7,
          isShow: true,
          isTotal: false,
          isSort: true,
        },

        {
          name: "快递公司",
          field: "expressName",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "快递单号",
          field: "courierNumber",
          width: "100",
          sort: 5,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "包裹状态",
          field: "packageStatusShow",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "拒签类型",
          field: "rejectionStatusShow",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "处理状态",
          field: "handleStatusShow",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "退货快递单号",
          field: "rejectionCourierNumber",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "退货快递费",
          field: "expressFee",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        /* {
                      name: "货物名称",
                      field: "",
                      width: "80",
                      sort: 11,
                      isShow: true,
                      isTotal: false,
                      isSort: true,
                    },
                    {
                      name: "包裹件数",
                      field: "",
                      width: "80",
                      sort: 12,
                      isShow: true,
                      isTotal: true,
                      isSort: true,
                    },
                    {
                      name: "包裹重量(KG)",
                      field: "",
                      width: "140",
                      sort: 13,
                      isShow: true,
                      isTotal: true,
                      isSort: true,
                    },
                    {
                      name: "包裹体积(m³)",
                      field: "",
                      width: "140",
                      sort: 14,
                      isShow: true,
                      isTotal: true,
                      isSort: true,
                    },
                    {
                      name: "长宽高(cm)",
                      field: "",
                      width: "120",
                      sort: 1,
                      isShow: true,
                      isTotal: false,
                      isSort: true,
                    },

                    {
                      name: "包裹备注",
                      field: "",
                      width: "80",
                      sort: 15,
                      isShow: true,
                      isTotal: false,
                      isSort: true,
                    }, */
        {
          name: "拒签申请时间",
          field: "createTime",
          width: "140",
          sort: 15,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "申请人",
          field: "createUserName",
          width: "80",
          sort: 15,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
      ],
      tableData: [],
      totalArr: [], //需要合计的字段
      className: "", //导出需要
      fieldName: [], //下载字段名
      head: [], //下载表头字段
      fileList: [],
      uploadUrl: "", //上传路径
      sortName: "模板名", //排序名
      pageGroupName: "RejectReturn", //页面标识

      isActive: true, //输入框绑定动态class
      judge: false, //输入框绑定动态class
      MoreConditions: "更多条件", //输入框绑定动态class
      rjsArr: [], //  拒签类型数组
      singleData: [],
    };
  },
  updated() {
    if (this.show_sortTableHeard) {
      // 排序表头
      let arr = this.tabelHeadeTitle;
      for (var i = 0; i < arr.length; i++) {
        arr[i].sort = i + 1;
      }
      this.tabelHeadeTitle = arr;
    }
  },
  created() {
    this.uploadUrl = this.common.uploadUrl;
    if (this.originalTabelHeadeTitle.length == 0) {
      this.originalTabelHeadeTitle = JSON.parse(
        JSON.stringify(this.tabelHeadeTitle)
      );
    }
    this.getGroupFindFieldSort(); //  获取用户自定义表头
    this.myGetStatusValList("bill_rejection_v1.rejection_status"); //  获取公共状态展示值
  },
  mounted() {
    this.getData();
    // window.onresize = () => {
    //     return (() => {
    //         this.fetTableHeight();
    //     })();
    // };
  },
  watch: {},
  methods: {
    //表格样式i
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return "warning-row";
      }
    },
    getData() {
      // 获取数据
      this.queryData.pageStart = this.pageNum;
      this.queryData.pageTotal = this.size;
      if (this.time_first.length > 0) {
        this.queryData.rejectionStartTime = `${this.time_first[0]} 00:00:00`;
        this.queryData.rejectionEndTime = `${this.time_first[1]} 23:59:59`;
      }
      Api.rejectionList(this.queryData).then((res) => {
        if (res.data.status === "success") {
          this.className = res.data.result.className || "";
          this.tableData = res.data.result.data || [];
          this.total = res.data.result.pageCount || 0;
          this.fetTableHeight();
        }
      });
    },

    queryBtn_ok() {
      let nidlist = new Array();
      nidlist = tools.getOrderNum(this.number1);
      this.queryData.courierNumber = nidlist;
      this.pageNum = 1;
      this.getData();
    },

    more() {
      //  输入框绑定动态class
      this.isActive = !this.isActive;
      this.judge = !this.judge;
      this.fetTableHeight();
      if (this.judge) {
        this.MoreConditions = "收起条件";
      } else {
        this.MoreConditions = "更多条件";
      }
    },

    myGetStatusValList(status) {
      //   获取公共状态展示值--拒签类型
      Api.getStatusValList({
        tableAndFieldName: status,
      }).then((res) => {
        if (res.data.status === "success") {
          this.rjsArr = res.data.result || [];
        } else {
          this.$message.error(res.data.message);
        }
      });
    },

    NewReject() {
      //  新建拒收
      this.$router.push({
        path: "/BusinessManagement/Warehousing/RejectionTreatment",
        query: {
          status: "newAdd",
        },
      });
    },
    // 拒收处理
    RejectionTreatment() {
      if (this.singleData.length != 1) {
        this.$message.warning("请选择一个包裹");
        return;
      }
      //  拒收处理
      this.$router.push({
        path: "/BusinessManagement/Warehousing/RejectionTreatment",
        query: {
          number: this.singleData[0].courierNumber,
          status: "edit",
        },
      });
    },
    resetFeild() {
      //  重置
      this.number1 = "";
      this.queryData = {};
    },

    rejection() {
      //  删除拒收
      if (this.singleData.length > 0) {
        this.$confirm("是否删除拒收申请?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          let ids = [];
          this.singleData.forEach((item) => {
            ids.push(item.id);
          });
          let param = {
            // rejectionIds: this.singleData[0].id
            rejectionIds: ids,
          };
          param.sign = tools.getSign(param);
          Api.delRefuse(param).then((res) => {
            if (res.data.status === "success") {
              this.$message.success(res.data.message);
              this.getData();
            } else {
              this.$message.error(res.data.message);
            }
          });
        });
      } else {
        this.$message.warning("请选择一条数据");
      }
    },

    //删除
    delbut(nid) {
      var sid = nid;
      this.tableData1.splice(sid, 1);
    },
    //导出操作
    handleCommand_dc(command) {
      if (command == "dc") {
        // 导出
        this.exportBtn();
      } else if (command == "dcmb") {
        // 下载模板
        this.exportTemplateDownload();
      } else if (command == "scmb") {
        // 上传模板
        this.upLoadBtn();
      }
    },

    //打开
    returnGoods() {
      this.$router.push(
        "/BusinessManagement/Warehousing/RejectionTreatment?nid=2"
      );
    },

    // 上传导出模板
    uploadSectionFile(param) {
      //console.log(param);
      const file = param.file,
        fileName = file.name;

      // 根据后台需求数据格式
      const form = new FormData();
      // 文件对象
      form.append("file", file);
      // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      form.append("fileName", fileName);
      let signArr = [
        {
          key: "fileName",
          val: fileName,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      form.append("sign", sign);
      // 项目封装的请求方法，下面做简单介绍
      Api.uploadFile(form)
        .then((res) => {
          if (res.data.status == "success") {
            let fileName = res.data.result.fileName;

            let signArr = [
              {
                key: "groupName",
                val: this.pageGroupName,
              },
              {
                key: "templateName",
                val: this.sortName,
              },
              {
                key: "fileName",
                val: fileName,
              },
            ];
            let sign_ = tools.getMD5Sign(signArr);
            Api.saveExportTemplate({
              groupName: this.pageGroupName,
              templateName: this.sortName,
              fileName: fileName,
              sign: sign_,
            }).then((res) => {
              this.$message.success(res.data.message || "更新成功");
              this.show_upload = false;
            });
          }
        })
        .catch((err) => {});
    },

    // 下载用户导出模板
    exportTemplateDownload() {
      var fieldName = this.fieldName;
      var head = this.head;
      if (head.length == 0) {
        let fieldNames = [];
        let heads = [];
        for (var i = 0; i < this.myTableHeard.length; i++) {
          if (this.myTableHeard[i].isShow) {
            fieldNames.push(this.myTableHeard[i].field);
            heads.push(this.myTableHeard[i].name);
          }
        }
        fieldName = fieldNames;
        head = heads;
      }
      //加密数组格式
      let signArr = [
        {
          key: "fieldName",
          val: fieldName,
        },
        {
          key: "head",
          val: head,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      let param = {
        fieldName: fieldName,
        head: head,
        sign: sign,
      };
      Api.exportTemplateDownload(param).then((res) => {
        let downloadurl = res.data.result.fileUrl || "";
        //下载(这里其实是直接访问)
        var a = document.createElement("a");
        a.setAttribute("href", downloadurl);
        a.setAttribute("target", "_blank");
        a.click();
      });
    },
    // 真正导出
    templateDataExport(customizedExportId) {
      let that = this;
      let datas = that.tableData;
      let parm = {
        customizedExportId: customizedExportId, //模板id
        className: that.className,
        data: datas,
      };
      let signarr = tools.getSignArr(parm);
      let signs_e = tools.getMD5Sign(signarr);
      Api.templateDataExport({
        customizedExportId: customizedExportId,
        className: that.className,
        data: JSON.stringify(datas),
        sign: signs_e,
      }).then((res) => {
        //下载(这里其实是直接访问)
        let downloadurl = res.data.result.fileUrl;
        var a = document.createElement("a");
        a.setAttribute("href", downloadurl);
        a.setAttribute("target", "_blank");
        a.click();
      });
    },
    // 上传按钮
    upLoadBtn() {
      this.show_upload = true;
    },
    // 点击导出按钮
    exportBtn() {
      let that = this;
      let fieldName = [];
      let head = [];
      for (var i = 0; i < this.myTableHeard.length; i++) {
        if (this.myTableHeard[i].isShow) {
          fieldName.push(this.myTableHeard[i].field);
          head.push(this.myTableHeard[i].name);
        }
      }
      this.fieldName = fieldName;
      this.head = head;

      // 1,先获取用户导出模板
      Api.getUserExportTemplate({
        groupName: this.pageGroupName,
      }).then((res) => {
        if (res.data.result && res.data.result[0].customizedExportId) {
          // 有自定义模板

          let customizedExportId = res.data.result[0].customizedExportId;
          that.templateDataExport(customizedExportId);
        } else {
          tools.dynamicExport(
            this.myTableHeard,
            this.className,
            this.tableData,
            "拒收退货列表"
          );
        }
      });
    },
    // ===============================================导出部分=结束======

    /**
     * ******************************计算表格高度**开始*************************
     */
    // 重置table高度
    resetHeight() {
      return new Promise((resolve, reject) => {
        this.tableHeigth = 0;
        resolve();
      });
    },
    // 设置table高度
    fetTableHeight() {
      this.resetHeight().then((res) => {
        this.tableHeigth = this.getHeight();
        this.$nextTick(() => {
          this.$refs.mytable.doLayout();
        });
      });
    },
    // 合计
    getSummaries(param) {
      let that = this;
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        for (var i = 0; i < that.totalArr.length; i++) {
          if (column.property === that.totalArr[i]) {
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return tools.mathToFixed(tools.add(prev, curr), 2);
                } else {
                  return prev;
                }
              }, 0);
              sums[index] += "";
            } else {
              sums[index] = "---";
            }
          }
        }
      });
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      return sums;
    },
    // 获取表格高度
    getHeight() {
      let windonHeight = document.body.clientHeight;
      let queryHeigth;
      let btnListHeight = this.$refs.btnList.offsetHeight + 16; // 按钮列表  16==上下的margin
      if (this.judge == true) {
        queryHeigth = this.$refs.queryCriteria.offsetHeight; //查询框
      } else {
        queryHeigth = 50;
      }
      let pagsHeigth = 60; //分页组件
      var otherHeight = queryHeigth + btnListHeight + pagsHeigth + 30;
      return Math.floor(windonHeight - otherHeight) || "auto"; //
    },
    /**
     * ******************************计算表格高度**结束*************************
     */

    // 刷新页面==>
    reload_s() {
      this.reload(); //App.Vue里面的方法
    },

    // 获取自定义的排序,没有就用默认的
    getGroupFindFieldSort() {
      let that = this;
      Api.getGroupFindFieldSort({
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
      }).then((res) => {
        let arr = res.data.result || [];
        if (arr.length > 0) {
          if (arr[0].sortObject) {
            that.myTableHeard = JSON.parse(arr[0].sortObject);
            this.sortName = arr[0].sortName;
          }
        } else {
          that.myTableHeard = that.tabelHeadeTitle;
        }
        let totalArr = [];
        //提取需要合计的字段
        for (var i = 0; i < that.myTableHeard.length; i++) {
          if (that.myTableHeard[i].isTotal) {
            totalArr.push(that.myTableHeard[i].field);
          }
        }
        that.totalArr = totalArr;
      });

      that.$nextTick(() => {
        that.$refs.mytable.doLayout();
      });
    },
    // 设置模板按钮
    myModel() {
      let that = this;
      that.show_sortTableHeard = true;
      that.tabelHeadeTitle = JSON.parse(JSON.stringify(that.myTableHeard));
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength === that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },
    // 表格选择事件
    handleSelectionChange(e) {
      this.singleData = e;
    },
    // 排序==>取消按钮
    cancelSortHeard() {
      this.show_sortTableHeard = false;
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
    },
    // 排序==>确认按钮
    confirmOk(e) {
      this.tabelHeadeTitle = e;
      this.myTableHeard = this.tabelHeadeTitle;
      let sortObject = this.myTableHeard;
      let signs = [
        {
          key: "sortName",
          val: this.sortName, //模板名称
        },
        {
          key: "groupName",
          val: this.pageGroupName, //页面标识
        },
        {
          key: "sortObject",
          val: JSON.stringify(sortObject),
        },
      ];

      let sign = tools.getMD5Sign(signs);

      let param = {
        // apiPath: apiList.api_packageList, //综合查询接口路径
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
        sortName: this.sortName, //综合查询接口路径
        sortObject: JSON.stringify(sortObject),
        sign: sign,
      };
      Api.pushFindFieldSort(param).then((res) => {
        this.$message.success(res.data.message || "操作成功");
        this.getGroupFindFieldSort();
      });
      this.show_sortTableHeard = false; //关闭弹窗
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      this.show_sortTableHeard = false;
    },
    // 点击某一项
    checktab(idx, e) {
      let that = this;
      that.tabelHeadeTitle[idx].isShow = e;
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },
    //全选
    checkAll(e) {
      let that = this;
      this.allCheck = e;
      for (var j = 0; j < this.tabelHeadeTitle.length; j++) {
        this.tabelHeadeTitle[j].isShow = e;
      }
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
    },
    //关闭弹窗
    closeDias(e) {
      this.show_sortTableHeard = e;
    },

    //分页
    handleSizeChange(val) {
      this.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getData();
    },
    // 上一页
    handlePrevPage() {
      this.pageNum = this.pageNum - 1 || 1;
      this.getData();
    },
    // 下一页
    handleNextPage() {
      this.pageNum = this.pageNum + 1 || 1;
      this.getData();
    },
    // ===============定制表格和排序以及分页 结束========
  },
};
</script>

<style scoped lang="scss">
.tableBox {
  height: calc(100% - 260px);
}

.inpustyle {
  width: 400px;
}

.addbut {
  margin-left: 20px;
}

.headebut {
  width: 100%;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.tableBox {
  height: calc(100% - 260px);
}

.colorStyle {
  color: #f00;
}

.mdTitName {
  height: 24px;
  border: 1px solid #d7d7d7;
  text-align: center;
  min-width: 100px;
}
</style>
